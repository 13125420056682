import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@contexts/Global';
import { useTranslation } from 'react-i18next';
import './applicationState.scss'
import { applicationStateIcon } from '@icons';

export default function ApplicationState() {
    const { globalData } = useContext(GlobalContext);
    const { t } = useTranslation(['application-in-progress', 'common']);
    const [pageHeader, setPageHeader] = useState("");
    const [instructionParagraphs, setInstructionParagraphs] = useState<Array<string>>([]);
    const [stateIcon, setStateIcon] = useState(null);

    useEffect(() => {
        let prefix = globalData.currentPartnerConfig?.onboardSpend ? "onboard-spend." : "";

        const instructions: Array<string> = t(
            `${globalData.offerStatus}.${prefix}paragraphs`,
            {
                returnObjects: true, defaultValue: []
            }
        );

        setInstructionParagraphs(instructions);
        setPageHeader(t(`${globalData.offerStatus}.${prefix}header`));
        setStateIcon(applicationStateIcon(globalData.offerStatus || ''));
    }, [globalData.offerStatus, t, globalData.currentPartner, globalData.currentPartnerConfig])

    return (
        <div className="application-state">
            <div className="state-icon-container">
                {stateIcon && React.createElement(stateIcon, null, '')}
            </div>
            <div className="state-text-container">
                <div className="header">
                    {pageHeader}
                </div>

                {
                    instructionParagraphs.map((paragraph: string, index: number) => (
                        <p className="paragraph" key={index}>
                                {paragraph}
                            </p>
                        ))
                }

            </div>
        </div>
    )
}
