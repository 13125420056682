// Phone previews
import { ReactComponent as WaitingForClient } from './PhonePreviews/WaitingForClient.svg';
import { ReactComponent as OfferLoaded } from './PhonePreviews/OfferLoaded.svg';
import { ReactComponent as LoanApproved } from './PhonePreviews/LoanApproved.svg';
import { ReactComponent as LoanReview } from './PhonePreviews/LoanReview.svg';
import { ReactComponent as AdBlocker } from './adblock.svg';

// Misc
import { ReactComponent as EmptyResults } from './EmptyResults.svg';
import { ReactComponent as UniversalShutdown } from './UniversalShutdown.svg';

const MAP_OFFER_STATUS_TO_PHONE_PREVIEWS: any = {
    wait_for_client: WaitingForClient,
    offer_loaded: OfferLoaded,
    loan_approved: LoanApproved,
    loan_review: LoanReview,
    loan_reconfirm: LoanReview
};

export const phonePreviewByOfferStatus = (status: string) => MAP_OFFER_STATUS_TO_PHONE_PREVIEWS[status];

export { EmptyResults, UniversalShutdown, AdBlocker };