import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { iconByStep } from '@icons';
import { GlobalContext } from '@contexts/Global';

export default function TrackerStep({ step, className, index, name }: any) {
    const { globalData } = useContext(GlobalContext);
    const [status, setStatus] = useState(0);
    const STEP_CLASS = ['idle', 'current', 'done'];

    const { t } = useTranslation(['tracker', 'common']);

    useEffect(() => {
        if (index > globalData.currentStep) setStatus(0);
        if (index === globalData.currentStep) setStatus(1);
        if (index < globalData.currentStep) setStatus(2);
    
    }, [globalData.currentStep, index]);

    return (
        <div className={`step ${className} ${STEP_CLASS[status]} p-0 ${step} `}>
            <div>
            <div className="step-wrapper">
                { step && iconByStep(step) && React.createElement(iconByStep(step), null, '') }
                <div className="small-marble"/>
            </div>
            <p className="step-name">
                    {t(`steps.${name}`)}
            </p>
            </div>

            <div className="left-line"></div>
            <div className="right-line"></div>
        </div>
    )
}
