import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {applicationInProgressIcon} from '@icons';
import './waitForTheClient.scss';
import { GlobalContext, GlobalData } from '@contexts/Global';
import { MiniEstimatedResults } from '../';
import useTabiService from '@hooks/useTabiService';
import { useTracking } from '@hooks/useTracking';
import { formatPhone } from '@helpers/Utils';
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import ApplicationOptions from './components/applicationOptions';
import { APPLICATION_STATUSES } from '@helpers/Constants';

export default function WaitForTheClient() {
    const { globalData, setGlobalData, refs: { leaveConfirmationModal } } = useContext(GlobalContext);
    const [resendBy, setResendBy] = useState('email');
    const [resendOptions, setResendOptions] = useState(['email', 'sms']);
    const [resendAvailable, setResendAvailable] = useState(false);

    const { t } = useTranslation(['application-in-progress']);
    const [{ order_id, tenant_id }] = useParamsFromSessionStorage();
    const tabiApi = useTabiService();
    const history = useHistory();
    const { trackClick } = useTracking();

    const LinkIcon = applicationInProgressIcon('link');
    const ApplicationFormIcon = applicationInProgressIcon('applicationForm');
    const CheckmarkIcon = applicationInProgressIcon('checkmark');
    const [isOnboardSpend, setIsOnboardSpend] = useState(false);
    const [isSameDevice, setIsSameDevice] = useState(false);
    const [transSufix, setTransSufix] = useState("");
    const [transPrefix, setTransPrefix] = useState("");
    const [instructions, setInstructions] = useState<Array<string>>([]);
    const [extraInstructions, setExtraInstructions] = useState<Array<string>>([]);

    useEffect(() => {
        if (!globalData.currentPartnerConfig) return;

        setIsOnboardSpend(globalData.currentPartnerConfig.onboardSpend);
        setIsSameDevice(globalData.applicationOnSameDevice)
    }, [globalData.currentPartnerConfig, globalData.applicationOnSameDevice])

    useEffect(() => {
        if (!isOnboardSpend) return;

        setResendOptions(isSameDevice ? [] : ["email"])
        setTransPrefix(isOnboardSpend ? "onboard-spend." : "");
        setTransSufix(isOnboardSpend && isSameDevice ? "-same-device" : "")
        console.log("Application on the same device =", isSameDevice, isOnboardSpend)
    }, [isOnboardSpend, t, isSameDevice, setTransSufix]);

    useEffect(() => {
        setInstructions(t(`${globalData.offerStatus}.${transPrefix}instructions${transSufix}`, { returnObjects: true, defaultValue: [] }))
        setExtraInstructions(t(`${globalData.offerStatus}.${transPrefix}extra-instructions`, { returnObjects: true, defaultValue: [] }))
    }, [globalData.offerStatus, transPrefix, transSufix, setInstructions, t])

    const handleResend = useCallback(() => {
        if (!tabiApi) return;

        tabiApi.sendOffer(order_id, resendBy);
    }, [tabiApi, order_id, resendBy])

    const handleEdit = useCallback(() => {
        trackClick('edit-application');

        leaveConfirmationModal.current.actions = [() => {
            setGlobalData((gd: GlobalData) => {
                return {
                    ...gd,
                    tripInfo: {
                        ...gd.tripInfo,
                        offerId: null,
                        order_id: null
                    }
                }
            })
        }]
        leaveConfirmationModal.current.showModal(() => {
            leaveConfirmationModal.current.actions = [];
        });

        history.push('/'+ tenant_id +'/application');
    }, [history, tenant_id, trackClick, setGlobalData, leaveConfirmationModal]);

    const handleNewApplication = useCallback(() => {
        leaveConfirmationModal.current.actions = [() => {
            sessionStorage.removeItem('order_id');
            setGlobalData((gd: GlobalData) => {
                return {
                    ...gd,
                    offerId: null,
                    offerStatus: APPLICATION_STATUSES.send_application
                }
            });
        }]
        leaveConfirmationModal.current.showModal(() => {
            leaveConfirmationModal.current.actions = [];
        });

        history.push('/'+ tenant_id +'/application/')
    }, [tenant_id, history, setGlobalData, leaveConfirmationModal]);

    return (
        <div className="row wait_for_client">
            <div className="col-12 col-md-7">
                <div className={`ball-title ${globalData.offerStatus === 'wait_for_client' ? 'active': 'disabled'}`}>
                    <div className="icon">
                        { globalData.offerStatus === 'wait_for_client' && LinkIcon && React.createElement(LinkIcon, null, '')}
                        { globalData.offerStatus === 'offer_loaded' && CheckmarkIcon && React.createElement(CheckmarkIcon, null, '') }
                    </div>

                    <span>
                        { globalData.offerStatus === 'wait_for_client' && t(`wait_for_client.${transPrefix}awaiting-your-client${transSufix}`) }
                        { globalData.offerStatus === 'offer_loaded' && t(`wait_for_client.${transPrefix}client-clicked-link${transSufix}`) }
                    </span>
                </div>

                <div className={`middle-box ${globalData.offerStatus} ${globalData.offerStatus === 'wait_for_client' ? 'active': 'disabled'}`}>
                    {
                        instructions
                            .map((paragraph: string, index: number) => (<span className="paragraph" key={index}>
                                <Trans components={[<b></b>]}>
                                    {paragraph}
                                </Trans>
                            </span>))
                    }

                    {
                        globalData.offerStatus === 'wait_for_client' && resendOptions.map((option: string) => (
                            <div className="resend-option" key={option}>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        // [SMS_OUTAGE] In case of sms outage, just uncomment this
                                        disabled={!resendAvailable /*|| (option === 'sms' && globalData.tripInfo.localization.country === 'US')*/}
                                        type="radio"
                                        name="resend-option"
                                        id={`resend-option-${option}`}
                                        onChange={() => setResendBy(option)}
                                        checked={resendBy === option}/>
                                    <label className="form-check-label" htmlFor={`resend-option-${option}`}>
                                        {t(`wait_for_client.resend-option.${option}`)}
                                    </label>
                                </div>
                                <div className="client-data">
                                    {option === 'sms' ? formatPhone(globalData.tripInfo?.billing_contact?.phone) : globalData.tripInfo?.billing_contact?.email}
                                </div>
                            </div>
                        ))
                    }

                    <ApplicationOptions
                        setResendAvailable={setResendAvailable}
                        resendAvailable={resendAvailable}
                        showOn={APPLICATION_STATUSES.wait_for_client}
                        handleEdit={handleEdit}
                        handleNewApplication={handleNewApplication}
                        handleResend={handleResend}/>

                </div>

                <div className={`ball-title ${globalData.offerStatus === 'offer_loaded' ? 'active': 'disabled'}`}>
                    <div className="icon">
                        { ApplicationFormIcon && React.createElement(ApplicationFormIcon, null, '')}
                    </div>

                    <span>
                        { globalData.offerStatus === 'wait_for_client' && t(`wait_for_client.${transPrefix}application-form${transSufix}`) }
                        { globalData.offerStatus === 'offer_loaded' && t(`wait_for_client.${transPrefix}your-client-is-filling-form`) }
                    </span>
                </div>

                    {
                        extraInstructions.length > 0 &&
                            <div className="middle-box borderless">
                                {
                                    extraInstructions
                                        .map((paragraph: string, index: number) => (<span className="paragraph" key={index}>
                                            <Trans components={[<b></b>]}>
                                                {paragraph}
                                            </Trans>
                                        </span>))
                                }
                            </div>
                    }

                <ApplicationOptions
                        setResendAvailable={setResendAvailable}
                        resendAvailable={resendAvailable}
                        showOn={APPLICATION_STATUSES.offer_loaded}
                        handleEdit={handleEdit}
                        handleNewApplication={handleNewApplication}
                        handleResend={handleResend}/>


            </div>
            <div className="col-12 col-md-5 order-first order-md-last">
                <MiniEstimatedResults />
            </div>
        </div>
    )
}
