import React, { useCallback, useContext, useRef, useState } from 'react'
import { PartnerTypes } from '@helpers/Constants'
import { formatCurrency } from '@helpers/Utils'
import { Copy } from '@components'
import ModalBookingCompleted from '../ModalBookingCompleted/modalBookingCompleted'
import ModalBookingFailed from '../ModalBookingFailed/modalBookingFailed'
import { GlobalContext, GlobalData } from '@contexts/Global'
import { Trans, useTranslation } from 'react-i18next'
import { useTracking } from '@hooks/useTracking'
import useTabiService from '@hooks/useTabiService'
import { applicationInProgressIcon } from '@icons'
import { trigger } from '@helpers/CustomEvents'
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage';
import PaymentConfirmationDialogue from './paymentConfirmationDialogue';


export default function RegularReceivePayment({ card, partnerType } : any) {
    const { globalData: { tripInfo: { localization: { language, currency }, ...tripInfo }, currentPartnerConfig, offerStatus }, setGlobalData } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation(['application-in-progress']);
    const tabiApi = useTabiService();
    const { trackResponse, trackAxiosResult } = useTracking();
    const [{ order_id }] = useParamsFromSessionStorage();

    const cardIcon = applicationInProgressIcon('cardIcon');
    const bookingCompleteModal : any = useRef();
    const bookingFailedModal : any = useRef();

    const [confirmationIds, setConfirmationIds] = useState<string[]>(['']);

    const handleCompleteBooking = () => {
        setLoading(true);
        if (currentPartnerConfig?.hidePaymentConfirmation)
            onValidationSuccess()
        else
            trigger('form:validate-inputs-confirmation-numbers', {});
    }

    const onValidationSuccess = useCallback(() => {
        trackResponse(confirmationIds.join(','), order_id)
        tabiApi.confirmOrder(order_id, confirmationIds.join(','))
               .then((data: any) => {
                    bookingCompleteModal.current?.showModal();

                    tabiApi.offerStatus(order_id).then(({data}: any) => {
                        setGlobalData((gd: GlobalData) => {
                            if (gd.offerStatus !== data.status) trackAxiosResult('status-poll', 'get', {
                                status: data.status,
                                'status-stack': data.status_stack,
                                order_id
                            })

                            return {
                                ...gd,
                                offerStatus: data.status,
                            }

                        });
                    });
               })
               .finally(() => {
                    setLoading(false);
               });
    }, [tabiApi, order_id, confirmationIds, trackResponse, setGlobalData, trackAxiosResult])

    const onValidationFail = () => {
        bookingFailedModal.current?.showModal();
        setLoading(false);
    }

    const translationPrefix = currentPartnerConfig?.onboardSpend ? "onboard-application": "regular-application"

    return (
        <div className="receive-payment">
            <p className="title">
                { t(`receive_payment.${translationPrefix}.title`, { kind: t(`receive_payment.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}`) })}
            </p>
            <div className="box-content">
                <p>
                    { t(`receive_payment.${translationPrefix}.use-the-virtual-card`)}
                </p>

                <div className="icon">
                    { cardIcon && React.createElement(cardIcon, null, '')}
                </div>

            </div>
            <div className="box-content left-border-dashed">
                <p className="orange-text">
                    <Trans
                        t={t}
                        i18nKey='receive_payment.do-not-close'
                        components={[
                            <span className="enfasis-text" />,
                        ]}
                    />
                </p>
                <p>
                    <span>
                        <Trans
                            t={t}
                            i18nKey={`receive_payment.${translationPrefix}.card-use`}
                            values={{
                                amount: formatCurrency(tripInfo.order_amount, language || 'en', currency || 'USD', 2),
                                use: partnerType === PartnerTypes.Travel ?
                                    t(`receive_payment.${translationPrefix}.complete-the-transaction`) :
                                    t(`receive_payment.${translationPrefix}.pay-for-the-item`)
                            }}
                            components={[
                                <span className="enfasis-text" />,
                            ]}

                        />
                    </span>
                </p>
                { card &&
                    <React.Fragment>
                        <div className="card col-6 invisible-card">
                            <div className="row">
                                <div className="col-5 card-label" data-qa="receive-payment-card-name-label">
                                    { t('receive_payment.card.name-on-card')}
                                </div>
                                <div className="col-7" data-qa="receive-payment-card-name-value">
                                    {card?.contact?.first_name} {card?.contact?.last_name}
                                </div>
                                <div className="col-5 card-label" data-qa="receive-payment-address-label">
                                    { t('receive_payment.card.address')}
                                </div>
                                <div className="col-7" data-qa="receive-payment-address-value">
                                    {card?.contact?.street_address}, {card?.contact?.city},  {card?.contact?.region}
                                </div>
                            </div>
                        </div>

                        <div className="card col-6">
                            <div className="row">
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.card-type')}
                                </div>
                                <div className="col-7" id="virtual-card-type">
                                    {card?.card_type}
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.card-number')}
                                </div>
                                <div className="col-7" id="virtual-card-number">
                                    {card?.card_number.match(/.{1,4}/g).join(" ")} <Copy textToCopy={card?.card_number} />
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.expiration')}
                                </div>
                                <div className="col-7" id="virtual-card-expiration">
                                    {card?.expiration_month}/{card?.expiration_year} <Copy textToCopy={`${card?.expiration_month}/${card?.expiration_year}`} />
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.security-code')}
                                </div>
                                <div className="col-7" id="virtual-card-security-code">
                                    {card?.card_ccv} <Copy textToCopy={card?.card_ccv} />
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.billing-zip')}
                                </div>
                                <div className="col-7" id="virtual-card-postal-code">
                                    {card?.contact?.postal_code} <Copy textToCopy={card?.contact?.postal_code} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                { !card && offerStatus === 'trip_booked' && <div className="card col-12">
                    <p>
                        The card was not fetched, because this trip is already booked
                    </p>
                </div>}

            </div>

            {!currentPartnerConfig?.hidePaymentConfirmation &&
            <PaymentConfirmationDialogue
                partnerType={partnerType}
                onValidationSuccess={onValidationSuccess}
                onValidationFail={onValidationFail}
                confirmationIds={confirmationIds}
                setConfirmationIds={setConfirmationIds}
            />}

            <button className="btn btn-primary complete-booking"
                    disabled={loading}
                    onClick={handleCompleteBooking}>
                {t('receive_payment.buttons.complete-uplift-booking')}
                { loading && <span className="spinner-border spinner-border-sm"></span> }
            </button>

            <ModalBookingCompleted ref={bookingCompleteModal} />
            <ModalBookingFailed ref={bookingFailedModal} />

        </div>
    )
}
