// Countries
import { ReactComponent as CALogo } from './countries/CA.svg';
import { ReactComponent as USLogo } from './countries/US.svg';
import { ReactComponent as OtherCountryLogo } from './countries/Other.svg';

// Trips
import { ReactComponent as CarsLogo } from './trips/car.svg';
import { ReactComponent as CuriseLogo } from './trips/cruise.svg';
import { ReactComponent as FlightLogo } from './trips/flight.svg';
import { ReactComponent as FractionalLogo } from './trips/fractional.svg';
import { ReactComponent as HotelLogo } from './trips/hotel.svg';
import { ReactComponent as InsuranceLogo } from './trips/insurance.svg';
import { ReactComponent as MembershipLogo } from './trips/membership.svg';
import { ReactComponent as PackageLogo } from './trips/package.svg';
import { ReactComponent as OtherTripLogo } from './trips/other.svg';

// Helpful tips
import { ReactComponent as Calendar } from './tips/calendar.svg';
import { ReactComponent as Coin } from './tips/coin.svg';
import { ReactComponent as CreditScore } from './tips/credit_score.svg';
import { ReactComponent as Fee } from './tips/fee.svg';
import { ReactComponent as InterestRates } from './tips/interest_rates.svg';
import { ReactComponent as Timer } from './tips/timer.svg';
import { ReactComponent as NoAPR } from './tips/no-apr.svg';
import { ReactComponent as Play } from './tips/play.svg';
import { ReactComponent as Calculator } from './tips/calculator.svg';
import { ReactComponent as CalculatorPercent } from './tips/calculator-percent.svg';
import { ReactComponent as WorldGreen } from './tips/world-green.svg';
import { ReactComponent as Sailing } from './tips/sailing.svg';
import { ReactComponent as Cart } from './tips/cart.svg';
import { ReactComponent as Percent } from './tips/percent.svg';
import { ReactComponent as CircleOne } from './tips/numbers/one.svg';
import { ReactComponent as CircleTwo } from './tips/numbers/two.svg';
import { ReactComponent as Application } from './tips/application.svg';
import { ReactComponent as WorldGreenOutline } from './tips/world-green-outline.svg';
import { ReactComponent as ShoppingBag } from './tips/shopping-bag.svg';
import { ReactComponent as AmountRequested } from './tips/amount-requested.svg';

// TrackSteps
import { ReactComponent as Send } from './tracker/send.svg';
import { ReactComponent as InProgress } from './tracker/in-progress.svg';
import { ReactComponent as ApplicationDesicion } from './tracker/application-desicion.svg';
import { ReactComponent as ClientDesicion } from './tracker/client-desicion.svg';
import { ReactComponent as Purchase } from './tracker/purchase.svg';

// Application Progress
import {ReactComponent as AgentIcon} from './applicationInProgress/agent.svg';
import {ReactComponent as LinkIcon} from './applicationInProgress/link.svg';
import {ReactComponent as ApplicationForm} from './applicationInProgress/applicationForm.svg';
import {ReactComponent as CheckmarkIcon} from './checkmark.svg';
import {ReactComponent as CopyIcon} from './copy.svg';
import { ReactComponent as LoanApprovedIcon } from './applicationInProgress/loanApproved.svg';
import { ReactComponent as LoanDocumentationIcon } from './applicationInProgress/loanDocumentation.svg';
import { ReactComponent as CardIcon } from './applicationInProgress/card.svg';
import { ReactComponent as FinishIcon } from './applicationInProgress/finish.svg';

// Misc
import {ReactComponent as WorldIcon} from './world.svg';
import {ReactComponent as InfoIcon} from './info-circle.svg';
import {ReactComponent as InfoIconFilled } from './info-circle-filled.svg';
import {ReactComponent as WarningIcon } from './warning-icon.svg';
import {ReactComponent as OpenInFullIcon } from './open-in-full.svg';
import {ReactComponent as LinkStartApplicationIcon } from './link.svg';
import {ReactComponent as QRCodeIcon } from './qr-code.svg';
import {ReactComponent as UpliftSmile } from './up-smile.svg';

const MAP_COUNTRY_CODES_AND_LOGOS: any = {
    US: USLogo,
    CA: CALogo,
    Other: OtherCountryLogo,
};

const MAP_TRIPS_AND_LOGOS: any = {
    car: CarsLogo,
    cruise: CuriseLogo,
    flight: FlightLogo,
    fractional: FractionalLogo,
    hotel: HotelLogo,
    insurance: InsuranceLogo,
    membership: MembershipLogo,
    package: PackageLogo,
    other: OtherTripLogo
};

const MAP_TIPS_AND_ICONS: any = {
    calendar: Calendar,
    coin: Coin,
    credit_score: CreditScore,
    fee: Fee,
    interest_rates: InterestRates,
    timer: Timer,
    no_apr: NoAPR,
    play: Play,
    calculator: Calculator,
    calculator_percent: CalculatorPercent,
    world_green: WorldGreen,
    world_green_outline: WorldGreenOutline,
    sailing: Sailing,
    cart: Cart,
    percent: Percent,
    circle_1: CircleOne,
    circle_2: CircleTwo,
    application: Application,
    shopping_bag: ShoppingBag,
    amount_requested: AmountRequested,
}

const MAP_STEPS_AND_ICONS: any = {
    send: Send,
    inProgress: InProgress,
    applicationDesicion: ApplicationDesicion,
    clientDesicion: ClientDesicion,
    purchase: Purchase,
}

const MAP_APPLICATION_IN_PROGRESS_ICONS: any = {
    link: LinkIcon,
    agent: AgentIcon,
    applicationForm: ApplicationForm,
    checkmark: CheckmarkIcon,
    cardIcon: CardIcon,
    finishIcon: FinishIcon,
    copy: CopyIcon,
    states: {
        loan_approved: LoanApprovedIcon,
        loan_review: LoanDocumentationIcon,
        loan_reconfirm: LoanDocumentationIcon
    }
}

export const logoByCountryCode = (countryCodes: string) => MAP_COUNTRY_CODES_AND_LOGOS[countryCodes];
export const logoByTrip = (trip: string) => MAP_TRIPS_AND_LOGOS[trip];
export const iconByTip = (tip: string) => MAP_TIPS_AND_ICONS[tip];
export const iconByStep = (step: string) => MAP_STEPS_AND_ICONS[step];
export const applicationInProgressIcon = (key: string) => MAP_APPLICATION_IN_PROGRESS_ICONS[key];
export const applicationStateIcon = (key: string) => MAP_APPLICATION_IN_PROGRESS_ICONS.states[key];

export { WorldIcon, InfoIcon, InfoIconFilled, WarningIcon, OpenInFullIcon, LinkStartApplicationIcon, QRCodeIcon, UpliftSmile};

