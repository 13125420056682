import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading, Tooltip } from '@components';
import { GlobalContext } from '@contexts/Global';
import { formatCurrency } from '@helpers/Utils';
import useMarketingService from '@hooks/useMarketingService';

import './miniEstimatedResults.scss';

export default function MiniEstimatedResults() {
    const { t, i18n } = useTranslation(['estimates-results']);
    const [estimatedResults, setEstimatedResults] = useState<any>(null);
    const { globalData } = useContext(GlobalContext);
    const api = useMarketingService();
    const locale = i18n.language;
    const currency = globalData.tripInfo?.localization?.country === 'US' ? 'USD' : 'CAD';

    useEffect(() => {
        if (!globalData.tripInfo.up_code) return;
        if (!api) return;
        if (estimatedResults) return;
        
        api?.estimateOffer({
             order: globalData.tripInfo, 
             estimates: { 
                 offer: { 
                     price_type: 'total',
                     value: globalData.tripInfo.order_amount
                }
            } 
        })
        ?.then(({ data: { product_offers: { pricing_offers: { offer } } }}: any) => {
            setEstimatedResults(offer.alt_offer ? offer.alt_offer : offer);
        })

    }, [globalData.tripInfo, api, estimatedResults]);

    if (!estimatedResults)
        return <div className="mini-estimated-results row">
            <Loading />
        </div>

    return (
        <div className={`mini-estimated-results row ${i18n.language}`}>
            { !estimatedResults  && <Loading /> }

            <div className="col-12">
                <p className="description">
                    { t('based-on', { payments: estimatedResults.number_of_payments, apr_percentage:  estimatedResults.apr/100 }) }
                </p>
            </div>
            <div className="col-12 segment">
                <div className="title">
                    { t('pay-today') } <Tooltip message={t(estimatedResults.pay_today === 0 ? 'tooltips.pay-today-no-apr' : 'tooltips.pay-today')} placement="top" />
                </div>
                <p className="big-price">
                    {formatCurrency(estimatedResults.pay_today, locale)} {currency}
                </p>
            </div>
            <div className="col-12 segment">
                <div className="description">
                    {t('payments-label', { payments: estimatedResults?.number_of_payments })} <Tooltip message={t('tooltips.monthly-payments')} placement="top" />
                </div>
                <p className="big-price">
                    {formatCurrency(estimatedResults.monthly_payment_amount, locale)} {currency}/month
                </p>
                <div className="divider"/>
            </div>
            <div className="col-12 segment d-md-none d-lg-block d-none">
                <div className="price-container">
                    <span className="price-description">{t('total-price')}</span>
                    <span className="price-value"> {formatCurrency(estimatedResults.price, locale)}</span>
                 </div>
                 <div className="price-container">
                    <span className="price-description">{t('finance-charges')} <Tooltip message={t('tooltips.finance-charges')} placement="top" /></span>
                    <span className="price-value"> {formatCurrency(estimatedResults.finance_charges, locale)}</span>
                 </div>
                 <div className="price-container">
                    <span className="price-description bold">{t('total-payments')}</span>
                    <span className="price-value bold"> {formatCurrency(estimatedResults.grand_total, locale)}</span>
                 </div>
            </div>

            <div className="col-12 segment d-md-block d-lg-none">
                <div className="price-container row">
                    <span className="price-description col-12 ">{t('total-price')}</span>
                    <span className="price-value-small col-12 text-left"> {formatCurrency(estimatedResults.total_payments, locale)}</span>
                 </div>
                 <div className="price-container row">
                    <span className="price-description col-12">{t('finance-charges')}</span>
                    <span className="price-value-small col-12 text-left"> {formatCurrency(estimatedResults.finance_charges, locale)}</span>
                 </div>
                 <div className="price-container row">
                    <span className="price-description bold col-12">{t('total-payments')}</span>
                    <span className="price-value-small bold col-12 text-left"> {formatCurrency(estimatedResults.grand_total, locale)}</span>
                 </div>
            </div>
            <div className="col-12 segment">
                <p className="note">
                    {t('note')}
                </p>
            </div>
        </div>
    )
}
