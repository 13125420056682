import React, { useCallback } from 'react';
import { ReactComponent as Logo } from '@/logo.svg';
import { useTranslation } from 'react-i18next';

import './footer.scss';
import { useTracking } from '@hooks/useTracking';

export default function Footer() {
    const { t, i18n } = useTranslation(['auth']);
    const { trackLangChange } = useTracking();

    const year = new Date().getFullYear()

    const handleChangeLang = useCallback((lang: string) => {
        if (!trackLangChange) return;

        i18n.changeLanguage(lang);
        trackLangChange([lang]);
    }, [i18n, trackLangChange]);

    return (
        <div id="footer" className="row pt-3 color-grey-dark justify-content-center">
            <div className="col-12 col-sm-11">
                <div className="row align-items-end">
                    <div className="col-auto px-4">
                        <div id="footer-logo">
                            <Logo width="116" height="60"/>
                        </div>
                        <div className="row pt-2">
                            <div className="col-sm-12 col-md-auto">
                                <span >© {year} <a  target="_blank" rel="noreferrer" href={t('footer.uplift.link')}>{t('footer.uplift.title')}</a></span>
                            </div>
                            <div className="col-sm-12 col-md-auto">
                                <span tabIndex={0}>
                                    <a tabIndex={0} target="_blank" rel="noreferrer" href={t('footer.atou.link')}>
                                        {t('footer.atou.title')}
                                    </a>
                                </span>
                            </div>
                            <div className="col-sm-12 col-md-auto">
                                <span tabIndex={0}>
                                    <a tabIndex={0} target="_blank" rel="noreferrer" href={t('footer.tou.link')}>{t('footer.tou.title')}</a>
                                </span>
                            </div>
                            <div className="col-sm-12 col-md-auto">
                                <span tabIndex={0}>
                                    <a tabIndex={0} target="_blank" rel="noreferrer" href={t('footer.pp.link')}>{t('footer.pp.title')}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto px-4 pt-4">
                        <div className="lesser-bold">{t('support:title')}</div>
                        <div className="row pt-2">
                            <div className="col-auto">
                                {t('support:tel.agent.label')}<span  className="ml-1"><a href={t('support:tel.agent.link')} >{t('support:tel.agent.title')}</a></span>
                            </div>
                            <div className="col-auto">
                                {t('support:tel.guest.label')}<span  className="ml-1"><a href={t('support:tel.guest.link')} >{t('support:tel.guest.title')}</a></span>
                            </div>
                            <div className="col-auto">
                                <span ><a href={`mailto:${t('support:email')}`}>{t('support:email')}</a></span>
                            </div>
                            <div className="col-auto">
                                <div className="languages-wrapper">
                                    <span className={`language-selector btn ${i18n.language === 'en' && 'selected'}`} onClick={() => handleChangeLang('en')}>
                                        English
                                    </span>
                                    <span className={`language-selector btn ${i18n.language === 'fr' && 'selected'}`} onClick={() => handleChangeLang('fr')}>
                                        Français
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
