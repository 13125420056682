
import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import CurrencyInput from 'react-number-format'
import { off, on, trigger } from '@helpers/CustomEvents';
import "react-datepicker/dist/react-datepicker.css";
import "./validatedInput.scss";
import { useTracking } from '@hooks/useTracking';
import { Tooltip } from '@components';
import PasswordChecker from '@pages/Auth/components/ResetPassword/components/passwordChecker';

export declare type Option = {
    label: string,
    value: any,
    attrs?: any,
    enabled?: boolean
};

declare type ValidatedInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    onValidation?: Function,
    options?: Array<Option>
    label?: string,
    valueChecked?: any,
    style?: any;
    currency?: string;
    mask?: string;
    maxDate?: any;
    minDate?: any;
    startDirty?: boolean;
    noTracking?: boolean;
    tooltip?: string;
    showPasswordPolicy?: boolean;
};

export default function ValidatedInput({ id, value, name, onChange, onValidation, label, options = [], disabled, currency, mask, maxDate, minDate, noTracking,
                                         startDirty = false, valueChecked, min, type = 'text', checked, className, style, children, showPasswordPolicy, ...props} : ValidatedInputProps) {
    const [valueError, setValueError] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [isDirty, setIsDirty] = useState(startDirty);
    const [errorOnBottom, setErrorOnBottom] = useState(false);

    const { trackFormBlur, trackClick } = useTracking();

    const validation = useCallback((data: any) => {
        if (!onValidation) return;

        const [isValid, errorText, showErrorOnBottom] = onValidation(value);
        setValueError(!isValid);
        setErrorLabel(errorText || label);
        setErrorOnBottom(showErrorOnBottom)

        trigger(`input:validation-results-${id}`, { ...data.detail, id, isValid });
    }, [onValidation, setValueError, setErrorLabel, label, value, id])

    useEffect(() => {
        on(`input:validation-${id}`, validation);

        return () => off(`input:validation-${id}`, validation);
    }, [validation, id]);

    useEffect(() => {
        if (onValidation && isDirty) {
            const [isValid, errorText] = onValidation(value);
            setValueError(!isValid);
            setErrorLabel(errorText || label);
        }
    }, [value, setValueError, setErrorLabel, onValidation, isDirty, label]);

    useEffect(() => {
        if (Array.isArray(value) &&
            value?.length > 0 &&
            type === 'hidden') {
            setIsDirty(true);
        }
    }, [value, setIsDirty, type]);

    const handleOnChange = useCallback((e: any) => {
      // Remove any unicode ascii characters
      if (type === 'text')
      // eslint-disable-next-line
        e.target.value = e.target.value.replace(/[\x00-\x1F\x7F-\x9F\u200B-\u200F\u2028\u2029\uFEFF]/g, '');

        if (onChange) onChange(e);
        setIsDirty(true);
    }, [onChange, setIsDirty, type]);

    const handleCurrencyChange = useCallback(({ floatValue: value }: any) => {
        handleOnChange({ target: { value: value * 100 } });
    }, [handleOnChange]);

    const handleOnBlur = useCallback(() => {
        if (!trackFormBlur) return;
        if (noTracking) return;

        if (onValidation) {
            const [isValid, errorText] = onValidation(value);
            setValueError(!isValid);
            setErrorLabel(errorText || label);
        }

        trackFormBlur(id)
    }, [trackFormBlur, id, value, onValidation, label, noTracking]);

    if (type && type === "date")
      return (
        <div
          id={`group-${id}`}
          className={`form-group ${valueError ? "has-error" : ""} ${
            className || ""
          }`}
        >
          <label htmlFor={id}>{valueError ? errorLabel : label}</label>

          <div
            className={`form-control fake-input ${disabled ? "disabled" : ""}`}
            id={id}
          >
            <DatePicker
              onChange={(data) => handleOnChange({ target: { value: data } })}
              selected={value ? new Date(value.toString()) : null}
              disabled={disabled}
              showMonthDropdown
              showYearDropdown
              maxDate={maxDate || null}
              minDate={minDate || null}
              dropdownMode="select"
              onBlur={handleOnBlur}
              onCalendarOpen={() => {
                props.onClick && props.onClick({} as any);
              }}
              locale="en"
            />
          </div>
        </div>
      );

    if (type && type === "checkbox")
      return (
        <div
          id={`group-${id}`}
          className={`form-check ${valueError ? "has-error" : ""} ${
            className || ""
          }`}
          style={style}
        >
          <input
            className="form-check-input"
            type="checkbox"
            id={id}
            checked={checked}
            disabled={disabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            {...props}
            name={name}
          />
          <label className="form-check-label" htmlFor={id}>
            {children ? children : valueError ? errorLabel : label}
          </label>

          {props.tooltip && !disabled && <Tooltip message={props.tooltip} />}
        </div>
      );

    if (type && type === "currency")
      return (
        <div
          id={`group-${id}`}
          className={`form-group ${valueError ? "has-error" : ""} ${
            className || ""
          }`}
          data-currency={currency || "USD"}
        >
          <label htmlFor={id}>{valueError ? errorLabel : label}</label>
            <div className='position-relative input-and-currency-container'>
              <CurrencyInput
                id={id}
                className="form-control"
                disabled={disabled}
                value={value && (+value / 100)?.toString()}
                onValueChange={handleCurrencyChange}
                thousandSeparator=","
                decimalSeparator="."
                allowNegative={false}
                allowLeadingZeros={false}
                onBlur={handleOnBlur}
                onClick={(e: any) => props.onClick && props.onClick(e)}
                prefix="$ "
              />
              {children}
            </div>
        </div>
      );

    if (type && type === "radio")
      return (
        <div className={`${valueError ? "has-error" : ""} ${className || ""}`}>
          {options.map((opt, index) => (
            <div key={index}>
              <input
                className="form-check-input"
                type="radio"
                name={name}
                disabled={typeof opt.enabled !== "undefined" ? !opt.enabled : disabled}
                id={opt.attrs?.id || id + "-" + index}
                value={opt.value}
                onChange={handleOnChange}
                onClick={() => {
                  trackClick(opt.attrs?.id || id + "-" + index);
                  props.onClick && props.onClick({} as any);
                }}
                // onBlur={handleOnBlur}
                checked={valueChecked === opt.value}
              />
              <label
                className="form-check-label"
                htmlFor={opt.attrs?.id || id + "-" + index}
              >
                {opt.label}
              </label>
            </div>
          ))}
        </div>
      );

    if (type && type === "select")
      return (
        <div
          id={`group-${id}`}
          className={`form-group select-container ${
            valueError ? "has-error" : ""
          } ${className || ""} ${label ? ' has-label ' : ''} `}
        >
          <label htmlFor={id}>{valueError ? errorLabel : label}</label>
          <select
            className="form-control"
            id={id}
            disabled={disabled}
            value={value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          >
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      );

    if (mask) {
       return <div id={`group-${id}`} className={`form-group ${ valueError ? 'has-error' : '' } ${className || ''}`}>
            <label htmlFor={id} aria-label={`${id}-label`}>{valueError && !errorOnBottom ? errorLabel : label}</label>
            <InputMask mask={mask}
                id={id}
                onChange={handleOnChange}
                disabled={disabled}
                value={value}
                type="text"
                className="form-control"
                onBlur={handleOnBlur} />
                {
                  valueError && errorOnBottom &&
                    <label htmlFor={id} aria-label={`${id}-error-message`}>{ errorLabel }</label>
                }
        </div>
    }

    if (type === 'password' && showPasswordPolicy) {
      return <div id={`group-${id}`} className={`form-group ${ valueError ? 'has-error' : '' } ${className || ''}`}>
            <label htmlFor={id}>{ valueError ? errorLabel : label}</label>
            <PasswordChecker pass={value}/>
            <input id={id}
                   className="form-control"
                   disabled={disabled}
                   value={value}
                   onInput={handleOnChange}
                   onBlur={handleOnBlur}
                   onKeyUp={props.onKeyUp}
                   type={type}
                   name={name}
                   min={min}
                   step={props.step}
                   pattern={props.pattern}
                   {...props}
                   max={props.max}/>
        </div>
    }

    return (
        <div id={`group-${id}`} className={`form-group ${ valueError ? 'has-error' : '' } ${className || ''}`}>
            <label htmlFor={id}>{ valueError ? errorLabel : label}</label>
            <input id={id}
                   className="form-control"
                   disabled={disabled}
                   value={value}
                   onInput={handleOnChange}
                   onBlur={handleOnBlur}
                   onKeyUp={props.onKeyUp}
                   type={type}
                   name={name}
                   min={min}
                   step={props.step}
                   pattern={props.pattern}
                   {...props}
                   max={props.max}/>
        </div>
    )
}
