import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { formatCurrency } from '@helpers/Utils'
import ModalBookingCompleted from '../ModalBookingCompleted/modalBookingCompleted'
import ModalBookingFailed from '../ModalBookingFailed/modalBookingFailed'
import { GlobalContext, GlobalData } from '@contexts/Global'
import { Trans, useTranslation } from 'react-i18next'
import { useTracking } from '@hooks/useTracking'
import useTabiService from '@hooks/useTabiService'
import { applicationInProgressIcon, OpenInFullIcon } from '@icons'
import JsBarcode from 'jsbarcode'
import ModalBarcodeFullscreen from '../ModalBarcodeFullscreen/modalBarcodeFullscreen'
import PaymentConfirmationDialogue from './paymentConfirmationDialogue'
import { trigger } from '@helpers/CustomEvents'
import useParamsFromSessionStorage from '@hooks/useParamsFromSessionStorage'

export default function TokenizedRecievePayment({ card, partnerType } : any) {
    const { globalData: { currentPartnerConfig, tripInfo: { localization: { language, currency }, ...tripInfo }, offerStatus }, setGlobalData } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [cardString, setCardString] = useState<null | string>(null);

    const { t } = useTranslation(['application-in-progress']);
    const tabiApi = useTabiService();
    const { trackResponse, trackAxiosResult } = useTracking();
    const [{ order_id }] = useParamsFromSessionStorage();

    const cardIcon = applicationInProgressIcon('cardIcon');
    const bookingCompleteModal : any = useRef();
    const bookingFailedModal : any = useRef();

    const [confirmationIds, setConfirmationIds] = useState<string[]>(['']);

    const barcodeFullScreenModal: any = useRef();

    const handleCompleteBooking = () => {
        setLoading(true);
        if (currentPartnerConfig?.hidePaymentConfirmation)
            onValidationSuccess()
        else
            trigger('form:validate-inputs-confirmation-numbers', {});

    }

    const onValidationSuccess = useCallback(() => {
        trackResponse(confirmationIds.join(','), order_id)
        tabiApi.confirmOrder(order_id, confirmationIds.join(','))
               .then((data: any) => {
                    bookingCompleteModal.current?.showModal();

                    tabiApi.offerStatus(order_id).then(({data}: any) => {
                        setGlobalData((gd: GlobalData) => {
                            if (gd.offerStatus !== data.status) trackAxiosResult('status-poll', 'get', {
                                status: data.status,
                                'status-stack': data.status_stack,
                                order_id
                            })

                            return {
                                ...gd,
                                offerStatus: data.status,
                            }

                        });
                    });
               })
               .finally(() => {
                    setLoading(false);
               });
    }, [tabiApi, order_id, confirmationIds, trackResponse, setGlobalData, trackAxiosResult])

    const onValidationFail = () => {
        bookingFailedModal.current?.showModal();
        setLoading(false);
    }

    useEffect(() => {
        if (!card) return;

        setCardString(`${card.card_token}`);
    }, [card])

    useEffect(() => {
        if (!cardString) return;
        JsBarcode("#barcode", cardString);
    }, [cardString])

    return (
        <div className="receive-payment">
            <p className="title">
                { t('receive_payment.tokenized-application.title', { kind: t(`receive_payment.travel`) })}
            </p>
            <div className="box-content">
                <p>
                    { t('receive_payment.tokenized-application.use-the-virtual-card')}
                </p>

                <div className="icon">
                    { cardIcon && React.createElement(cardIcon, null, '')}
                </div>

            </div>
            <div className="box-content left-border-dashed">
                <p className="orange-text">
                    <Trans
                        t={t}
                        i18nKey='receive_payment.do-not-close'
                        components={[
                            <span className="enfasis-text" />,
                        ]}
                    />
                </p>
                <p>
                    <span>
                        <Trans
                            t={t}
                            i18nKey='receive_payment.onboard-application.card-use'
                            values={{
                                amount: formatCurrency(tripInfo.order_amount, language || 'en', currency || 'USD', 2)
                            }}
                            components={[
                                <span className="enfasis-text" />,
                            ]}

                        />
                    </span>
                </p>
                { card &&
                    <React.Fragment>
                        <div className="card col-12 invisible-card">
                            <div className="row">
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.name-on-card')}
                                </div>
                                <div className="col-7">
                                    {card.contact?.first_name} {card.contact?.last_name}
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.address')}
                                </div>
                                <div className="col-7">
                                    {card.contact?.street_address}, {card.contact?.city},  {card.contact?.region}
                                </div>
                                <div className="col-5 card-label">
                                    { t('receive_payment.card.session-id')}
                                </div>
                                <div className="col-7" id="virtual-card-number">
                                    {card.card_token}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }

                { !card && offerStatus === 'trip_booked' && <div className="card col-12">
                    <p>
                        { t('receive_payment.card-not-fetched-already-booked')}
                    </p>
                </div>}

                <div className='barcode-container' onClick={() => barcodeFullScreenModal.current.showModal()}>
                    <canvas id="barcode"></canvas>
                    <div className='barcode-fullscreen-icon'>
                        {React.createElement(OpenInFullIcon, null, null, '')}
                    </div>
                </div>

            </div>

            {!currentPartnerConfig?.hidePaymentConfirmation &&
            <PaymentConfirmationDialogue
                partnerType={partnerType}
                onValidationSuccess={onValidationSuccess}
                onValidationFail={onValidationFail}
                confirmationIds={confirmationIds}
                setConfirmationIds={setConfirmationIds}
            />}

            <div className="box-content">
                <button className="btn btn-primary complete-booking"
                        disabled={loading}
                        onClick={handleCompleteBooking}>
                    {t('receive_payment.buttons.complete-purchase')}
                    { loading && <span className="spinner-border spinner-border-sm"></span> }
                </button>

                <ModalBookingCompleted ref={bookingCompleteModal} />
                <ModalBookingFailed ref={bookingFailedModal} />
                <ModalBarcodeFullscreen ref={barcodeFullScreenModal} cardString={cardString}/>

            </div>
        </div>
    )
}
