import React, {useEffect, useState } from 'react';
import { on, off, trigger } from '@helpers/CustomEvents';

declare type ValidatedFormProps = React.FormHTMLAttributes<string> & {
    onValidationSuccess?: Function;
    onValidationFail?: Function;
    focusOnFail?: boolean;
    bypassChild?: boolean;
    validatedOnEmpty?: boolean;
    validateOnRender? : boolean;
};

export default function ValidatedForm({ onValidationSuccess, onValidationFail, focusOnFail = false, bypassChild = false, validatedOnEmpty= false, validateOnRender=false, ...props}: ValidatedFormProps) {
    const [inputs, setInputs] = useState<Array<any>>([]);
    const [validationResults, setValidationResults] = useState<Array<any>>([]);

    useEffect(() => {
        if(!props.children) return;

        const safeArrayChildren : Array<any> = Array.isArray(props.children) ? props.children : [props.children];
        const flatArrayChildren = safeArrayChildren.reduce((prev, child) => {
            return prev.concat( Array.isArray(child) ? child : [child])
        }, []);

        const newInputs = flatArrayChildren.map((child: any) => {
            if (!child?.props?.onValidation || typeof child?.props?.onValidation !== 'function') return null;

            return {
                id: child.props.id
            };
        }).filter((child: any) => child);

        setInputs(newInputs);
        setValidationResults(newInputs.map((input: any) => ({
            ...input,
            validationResult: true,
            validated: false,
        })));

    }, [props.children]);

    useEffect(() => {
        if (!props.name) {
            console.warn('Validation form doesnt have a name');
            return;
        }

        const inputValidationResultsHandler = (data: any) => {
            const { detail: { id, isValid, sendForm, validatedOnRender } } = data;

            const inputIndex: number = inputs.findIndex((input: any) => input.id === id);

            if (inputIndex === -1) return;

            const newValidationResult = {
               ...inputs[inputIndex],
               validationResult: isValid,
               validated: true,
               validatedOnRender,
               sendForm
           };

            setValidationResults((prev) => {
                prev[inputIndex] = newValidationResult;

                return [...prev];
            });
        };

        inputs.forEach((input: any) => {
            on(`input:validation-results-${input.id}`, inputValidationResultsHandler);
        });

        return () => {
            inputs.forEach((input: any) => {
                off(`input:validation-results-${input.id}`, inputValidationResultsHandler)
            });
        };
    }, [inputs, props.name, onValidationFail, onValidationSuccess, focusOnFail]);
    

    useEffect(() => {
        if (!props.name) {
            console.warn('Validation form doesnt have a name');
            return;
        }

        const onValidationTrigger = (data: any) => {

            if (validatedOnEmpty && inputs.length === 0) {
                onValidationSuccess && onValidationSuccess({});
                trigger(`form:validation-pass-${props.name}`, { name: props.name, validationResults: [{ validationResult: true } ]});
                return;
            }

            inputs.forEach((input: any) => {
                trigger(`input:validation-${input.id}`, data.detail);
            });
        };

        on(`form:validate-inputs-${props.name}`, onValidationTrigger);

        if (validateOnRender) trigger(`form:validate-inputs-${props.name}`, { validatedOnRender: true });

        return () => off(`form:validate-inputs-${props.name}`, onValidationTrigger);
    }, [inputs, props.name, validatedOnEmpty, onValidationSuccess, validateOnRender]);

    useEffect(() => {
        if (validationResults.length && validationResults.every((input: any) => input.validated)) {
            const event = validationResults.every((input: any) => input.validationResult) ? 'form:validation-pass' : 'form:validation-fail';
            const ignoreCallback = validationResults.every((input: any) => input.sendForm)
            
            trigger(`${event}-${props.name}`, { validationResults, name: props.name });

            if (ignoreCallback) return;

            switch(event) {
                case 'form:validation-pass':
                    onValidationSuccess && onValidationSuccess(validationResults);
                    break;
                case 'form:validation-fail':
                    onValidationFail && onValidationFail(validationResults)
                    
                    if (focusOnFail) {
                        const input = validationResults.find((input: any) => !input.validationResult);
            
                        if (!input) return;
            
                        const element : HTMLInputElement | null = document.querySelector(`#${input.id}`);    
                        element?.focus();
                    }
            }

        }
    }, [validationResults, focusOnFail, onValidationFail, onValidationSuccess, props.name]);


    useEffect(() => {
        if (!inputs) return;
        inputs.forEach((input: any) => {
            trigger(`input:validation-${input.id}`, { sendForm: true });
        });
    }, [inputs])


    if (bypassChild) return <React.Fragment>
        {props.children}
    </React.Fragment>

    return (
        <div className={props.className || ''}>
            {props.children}
        </div>
    )
}
