import { useCallback, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@contexts/Global';
import { useTranslation } from 'react-i18next';
import { APPLICATION_STATUSES } from '@helpers/Constants';

interface ApplicationOptionsProps {
    handleResend: Function,
    handleEdit: Function,
    handleNewApplication: Function,
    setResendAvailable: Function,
    resendAvailable: boolean,
    showOn: APPLICATION_STATUSES
}

export default function ApplicationOptions({
    handleResend, handleEdit, handleNewApplication,
    setResendAvailable, resendAvailable, showOn
}: ApplicationOptionsProps) {
    const { globalData } = useContext(GlobalContext);
    const { t } = useTranslation(['application-in-progress']);
    const [WAITING_TIME] = useState(30 * 1000);
    const [showRestartButton, setShowRestartButton] = useState(false);

    const startTimeoutForResend = useCallback(() => {
        setResendAvailable(false);
        return setTimeout(() => {
            setResendAvailable(true);
        }, WAITING_TIME);
      }, [WAITING_TIME, setResendAvailable])

    const handleResendCallback = useCallback(() => {
        startTimeoutForResend();
        handleResend();
    }, [handleResend, startTimeoutForResend])

    // Interval to enable the resend button
    useEffect(() => {
        if (!globalData.currentPartnerConfig) return;

        setShowRestartButton(globalData.currentPartnerConfig.onboardSpend && globalData.applicationOnSameDevice)

        const statusInterval = startTimeoutForResend()

        return () => {
            clearTimeout(statusInterval);
        };
    }, [WAITING_TIME, startTimeoutForResend, setShowRestartButton,
        globalData.currentPartnerConfig,
        globalData.applicationOnSameDevice]);

    return (
        <>
            {
                !showRestartButton &&
                globalData.offerStatus === showOn &&
                globalData.offerStatus === APPLICATION_STATUSES.wait_for_client &&
                    <button className="btn btn-resend" disabled={!resendAvailable} onClick={handleResendCallback}>
                        {t("wait_for_client.buttons.resend")}
                    </button>
            }
            {
                showRestartButton &&
                globalData.offerStatus === showOn &&
                globalData.offerStatus === APPLICATION_STATUSES.wait_for_client &&
                    <button className="btn btn-resend" disabled={!resendAvailable} onClick={() => handleNewApplication()}>
                        {t("wait_for_client.buttons.restart")}
                    </button>
            }
            {
                showOn === globalData.offerStatus &&
                    <button className="btn btn-edit" disabled={!resendAvailable} onClick={() => handleEdit()}>
                        {t("wait_for_client.buttons.edit")}
                    </button>
            }
        </>
    )
}
