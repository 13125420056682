import { ValidatedForm, ValidatedInput } from "@components";
import validator from 'validator';
import { GlobalContext } from "@contexts/Global";
import { PartnerTypes } from "@helpers/Constants";
import { applicationInProgressIcon } from '@icons'
import React, { Dispatch, useContext } from "react";
import { useTranslation } from "react-i18next";

declare type RegularRecievePaymentProps = {
    partnerType: PartnerTypes
    onValidationSuccess: Function
    onValidationFail: Function
    confirmationIds: string[]
    setConfirmationIds: Dispatch<React.SetStateAction<string[]>>
};


export default function PaymentConfirmationDialogue({
    partnerType, onValidationSuccess, onValidationFail, confirmationIds, setConfirmationIds
}: RegularRecievePaymentProps) {
    const { globalData: { offerStatus } } = useContext(GlobalContext);
    const { t } = useTranslation(['application-in-progress']);
    const finishIcon = applicationInProgressIcon('finishIcon');

    return (
        <div className="box-content">
            <p>
                {t('receive_payment.confirmation-title', {
                    confirmationCopy: t(`receive_payment.confirmation-number.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}-plural-could`)
                        .toLowerCase()
                })}
            </p>
            <p className="orange-text">
                <span>{t('receive_payment.one-confirmation-per-box', {
                    confirmationCopy: t(`receive_payment.confirmation-number.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}`).toLowerCase(),
                    confirmationCopyPlural: t(`receive_payment.confirmation-number.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}-plural`).toLowerCase()
                })}</span>&nbsp;
                <span className="enfasis-text"> "{t('receive_payment.add-more')}"</span>&nbsp;
                <span>{t('receive_payment.button-below')}</span>&nbsp;
            </p>
            <div className="icon">
                {finishIcon && React.createElement(finishIcon, null, '')}
            </div>

            {offerStatus !== 'trip_booked' &&
                <React.Fragment>
                    <ValidatedForm
                        name="confirmation-numbers"
                        onValidationSuccess={onValidationSuccess}
                        onValidationFail={onValidationFail}
                        className="confirmation-numbers row">
                        {
                            confirmationIds.map((confirmationId, index: number) => (
                                <ValidatedInput
                                    id={`confirmation-id-${index}`}
                                    value={confirmationId}
                                    key={index}
                                    onValidation={(value: string) => {
                                        if (!value || validator.isEmpty(value)) return [false];
                                        if (value.length > 255) return [false];
                                        return [true];
                                    }}
                                    className="col-4"
                                    onChange={({ target: { value } }: any) => { setConfirmationIds((prev) => { prev[index] = value; return prev.concat(); }); }}
                                    label={t(`receive_payment.inputs.confirmation-number.label.${partnerType === PartnerTypes.Travel ? 'travel' : 'non-travel'}`, { index: confirmationIds.length > 1 ? index + 1 : '' })}
                                    type="text">

                                </ValidatedInput>
                            ))
                        }
                    </ValidatedForm>
                    <div className="col-12 confirmation-buttons">
                        <button className="btn btn-add-more" onClick={() => {
                            setConfirmationIds((prev) => { prev.push(''); return prev.concat(); });
                        }}>
                            {t('receive_payment.add-more')}
                        </button>
                        {confirmationIds.length > 1 &&
                            <button className="btn btn-add-more btn-remove-confirmation" onClick={() => {
                                setConfirmationIds((prev) => { prev.pop(); return prev.concat(); });
                            }}>
                                {t('receive_payment.remove')}
                            </button>
                        }
                    </div>


                </React.Fragment>
            }

            {offerStatus === 'trip_booked' &&
                <div className="row">
                    {
                        confirmationIds.map((confirmationId, index) => (
                            <div className="col-3" key={index}>
                                <p className="sm-title">Confirmation ID {index + 1}</p>
                                <p>{confirmationId}</p>
                            </div>))
                    }
                </div>
            }

        </div>
    )
}